<template>
  <Stack tag="div" gap="xs">
    <Icon
      v-if="availability !== 'outOfStock'"
      :name="USPS[availability].icon"
      :size="16"
      class="ui-mt-3xs"
    />

    <Stack tag="div" gap="none" direction="col">
      <Text tag="p" size="caption" weight="bold">
        {{ USPS[availability].title }}
      </Text>

      <Stack tag="div" align="start" gap="2xs">
        <div
          v-if="indication"
          class="!ui-w-[10px] !ui-h-[10px] ui-aspect-square ui-rounded-[100%] ui-mt-3xs"
          :class="
            availability === 'collect'
              ? 'ui-bg-green-default'
              : 'ui-bg-orange-default'
          "
        />
        <Stack tag="div" gap="none" direction="col">
          <Text
            tag="p"
            size="caption"
            v-html="USPS[availability].description"
          />
          <button
            v-if="dropshipLink && availability === 'dropship'"
            @click="emit('open:dropship')"
          >
            <Text
              tag="span"
              size="caption"
              weight="bold"
              class="ui-text-mkm-blue-light ui-leading-xs"
              >Find Out More</Text
            >
          </button>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import Icon from "../Icon/Icon.vue";
import { IconName } from "../Icon/types";
import Stack from "../Layout/Stack/Stack.vue";
import Text from "../Typography/Text/Text.vue";
import { CardUSPProps } from "./types";

const emit = defineEmits<{
  (event: "open:dropship"): void;
}>();

const props = defineProps<CardUSPProps>();

const indication = computed(() => {
  return (
    props.availability === "collect" ||
    props.availability === "quote" ||
    props.availability === "outOfStock"
  );
});

type USPType = {
  icon: IconName;
  title: string;
  description: string;
};

type USPSType = {
  collect: USPType;
  delivery: USPType;
  quote: USPType;
  dropship: USPType;
  outOfStock: USPType;
};

const USPS: USPSType = {
  collect: {
    icon: "pointer-blue",
    title: "FREE Click & Collect",
    description: `Available to order at <span class="ui-font-bold" data-test-id="branchName">${props.branch}</span>`,
  },
  delivery: {
    icon: "truck",
    title: "FREE local delivery",
    description: "Restrictions apply",
  },
  quote: {
    icon: "pointer-blue",
    title: "FREE Click & Collect",
    description: `Available by quote only at <span class="ui-font-bold">${props.branch}</span>`,
  },
  dropship: {
    icon: "package",
    title: "FREE Delivery* available",
    description: "Delivered by one of our trusted suppliers.",
  },
  outOfStock: {
    icon: "add-star",
    title: "Item is not currently available",
    description: `Contact ${props.branch} on <a href="tel:${props.branchNumber}" rel="nofollow" class="ui-font-bold ui-text-mkm-blue-light">${props.branchNumber}</a> to see when we can get you what you need.`,
  },
};
</script>
