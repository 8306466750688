<template>
  <div
    class="ui-flex ui-items-center ui-justify-center ui-px-3xs ui-py-[2px] ui-gap-3xs ui-bg-white ui-border-[1px] ui-border-green-default ui-rounded-sm ui-text-xs ui-leading-[15px] ui-font-semibold"
  >
    <Icon
      name="sustainable-option"
      :size="12"
      :color="getColor('green-dark')"
    />
    <span class="ui-uppercase ui-text-green-dark">Greener Option</span>
    <span class="ui-text-green-dark" v-if="variant !== 'green'">-</span>
    <span
      class="ui-capitalize"
      :class="variantClasses[variant]"
      v-if="variant !== 'green'"
    >
      {{ variant }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import Icon from "../Icon/Icon.vue";
import type { GreenerOptionProps } from "./types";
import { getColor } from "mkm-avengers";

withDefaults(defineProps<GreenerOptionProps>(), {
  variant: "energy",
});

const variantClasses = {
  ["energy"]: "ui-text-orange-default",
  ["materials"]: "ui-text-blue-dark",
  ["planet"]: "ui-text-red-default",
  ["none"]: "",
  ["green"]: "ui-text-green-dark",
};
</script>
